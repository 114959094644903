<div class="container">
  <div class="address-holder">
    <h1>Herbarium Magdalena Dul</h1>
    <p> Gustawow 65</p>
    <p>26-220 Staporkow</p>
    <div class="contacts">
      <a href="mailto:office@herbarium.bio">office@herbarium.bio</a>
      <p>+48 798 771 980</p>
      <p>+44 797 211 58 68</p>
    </div>
  </div>
</div>
