<div class="container" fxFlex fxLayout="row">
  <div>
    <a class ="link" (click)="router.navigateByUrl('offer')"
      routerLinkActive #rla="routerLinkActive">our offer</a>
  </div>
  <div fxLayout="row" class="right-links">
    <div><a class ="link" (click)="router.navigateByUrl('about')"
      routerLinkActive #rla="routerLinkActive">about</a>
    </div>
    <div><a class ="link" (click)="router.navigateByUrl('contact')"
      routerLinkActive #rla="routerLinkActive">contact</a>
    </div>
  </div>
</div>
