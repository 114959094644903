import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../../services/product-service';
import { Category } from '../offer/product-category';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {

  category: Category | undefined;

  constructor(private activeRoute: ActivatedRoute, private productService: ProductService) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(p => {
      const categoryId = p.get('categoryId')?.toUpperCase();
      if (categoryId === undefined){
        this.category = undefined;
        return;
      }

      this.category = this.productService.getCategory(categoryId);
    });
  }

}
