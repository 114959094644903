import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '../views/offer/product-category';
import * as cat from '../../data/data.json';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private categories: Category[] = [];
  constructor(private httpClient: HttpClient) {
    this.categories = ((cat as any).default as Category[]);
    this.categories.forEach((c, categoryIndex) => {
      c.id = categoryIndex.toString();
      c.products.forEach((p, productIndex) => p.id = productIndex.toString());
    });
    this.categories = this.categories.sort((c1, c2) => c1.order < c2.order ? -1 : 1);
  }

  getCategory(categoryId: string): Category | undefined {
    return this.categories.find(c => c.id === categoryId);
  }

  getCategories(): Category[] {
    return this.categories;
  }
}
