import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './views/about/about.component';
import { ContactComponent } from './views/contact/contact.component';
import { MainComponent } from './views/main/main.component';
import { OfferComponent } from './views/offer/offer.component';
import { CategoryDetailsComponent } from './views/category-details/category-details.component';
import { CategoryComponent } from './views/category/category.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '', children: [
    { path: 'home', component: MainComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contact', component: ContactComponent },
  ]},
  { path: 'offer', component: OfferComponent },
  //{ path: 'offer', component: OfferComponent },
  { path: 'category', component: CategoryComponent, children: [
    { path: ':categoryId', component: CategoryDetailsComponent }
  ]}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
