import { Component, OnInit } from '@angular/core';
import { Product } from '../offer/product-category';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  categoryEl: string | undefined;
  productEl: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  produceJson(): void {
    if (this.productEl === undefined) {
      return;
    }

    const productsLines = this.productEl.split('\n');
    console.log(productsLines);
    const categoryName = this.categoryEl;

    console.log(productsLines);
    console.log(categoryName);

    const products = productsLines?.map((c, i) => {
      if (c.length <= 1) {
        return null;
      }

      const spaceIndex = c.indexOf(' ');
      const name = spaceIndex === -1 ? c : c.substr(0, spaceIndex);
      const type = spaceIndex === -1 ? '' : c.substr(spaceIndex + 1);
      return {
        id: (i + 1).toString(),
        name,
        type,
      } as Product;
    }).filter(c => c !== null);

    const category = {
      categoryName,
      imageUrl: '',
      products,
    };
    console.log(JSON.stringify(category));
  }
}
