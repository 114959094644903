import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product-service';
import { Category as Category } from './product-category';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})

export class OfferComponent implements OnInit {

  categories: Array<Category> = [];

  constructor(public router: Router, private productService: ProductService) { }

  ngOnInit(): void {
    this.categories = this.productService.getCategories();
  }
}
