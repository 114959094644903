<div class="container" fxFlex>
  <div fxLayout="column" fxFlexFill>
    <div class="center-container">
      <div class="categories-list" fxLayout="row"  fxLayout="row wrap" >
        <div class="category-card" *ngFor="let category of categories"
          [routerLink]="['/category',category.id]"
          routerLinkActive #rla="routerLinkActive"
          >
          <img class="category-link-image" src="{{category.imageUrl}}"/>
          <div class="category-name">{{category.categoryName}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
