<div class="container">
  <div class="content">
    <p>There is a continuing and expanding international demand for herbs, spices and essential oils. Social changes, including food diversification, the desire for new flavours, increasing importance of “ethnic” food and the increased importance of processed food, which requires condiments and aromatic herbs for its preparation. Herbarium was founded out of passion for the gifts of nature and admiration for Folk Medicine as well Medicinal Food and in response to the growing market demand for high-quality:</p>
    <div class="list">
      <ul>
        <li>Herbs</li>
        <li>Spices</li>
        <li>Seeds</li>
        <li>Essential Oils</li>
        <li>Plant extracts</li>
        <li>Raw materials</li>
        <li>NFC Juices and concentrates</li>
        <li>Dried fruits and vegetables</li>
        <li>Freeze-dried fruits and herbs</li>
      </ul>
    </div>
  </div>
</div>
